<template>
    <div>
        <v-form v-if="showPrintertype === false">
            <transition name="fade" mode="out-in">
                <v-container fluid key="type-selection" v-if="request.type == null">
                    <v-row>
                        <v-col v-for="item in types" :key="item.type" cols="12">
                            <v-btn style="height:70px;" block color="primary" @click="request.type = item.type">
                                <v-icon>
                                    {{ item.icon }}
                                </v-icon>
                                {{ item.label }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container key="form-container" v-else>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="10">
                                <h2>{{ headlines[request.type] }}</h2>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-end">
                                <v-btn color="primary" @click="reset" fab x-small>
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select :items="partners" v-model="request.partner_id" hide-details
                                    label="Tauschpartner" item-text="name" item-value="id" />
                            </v-col>
                        </v-row>
                    </v-container>

                    <!-- ___________ -->

                    <template v-if="showInputFields">

                        <v-container v-if="isSpecialPartner">
                            <v-radio-group v-model="request.carrierType" row
                                :rules="[v => !!v || 'Dieses Feld ist erforderlich']">
                                <v-radio label="Hiller" value="1"></v-radio>
                                <v-radio label="Subunternehmer" value="2"></v-radio>
                            </v-radio-group>
                            <v-row class="mx-1">
                                <v-text-field v-model="request.licensePlate" type="text" label="Kennzeichen"
                                    :rules="[v => !!v || 'Dieses Feld ist erforderlich']" />
                            </v-row>
                            <v-row class="mx-1">
                                <v-text-field v-if="showSubContractorField" v-model="request.subContractor" type="text"
                                    label="Subunternehmer" :rules="[v => !!v || 'Dieses Feld ist erforderlich']" />
                            </v-row>
                        </v-container>

                        <!-- ___________ -->

                        <v-container v-if="request.type === 'insert' || request.type === 'exchange'">
                            <v-subheader>Eingang</v-subheader>
                            <v-row class="mx-1">
                                <v-text-field v-model="request.input" type="number" min="0" append-outer-icon="add"
                                    prepend-icon="remove" @click:append-outer="increment('request', 'input')"
                                    @click:prepend="decrement('request', 'input')"
                                    :rules="[v => v >= 0 || 'Eingang muss positiv sein']" />
                            </v-row>
                        </v-container>
                        <v-container
                            v-if="request.type === 'removal' || request.type === 'damage' || request.type === 'exchange'">
                            <v-subheader>Ausgang</v-subheader>
                            <v-row class="mx-1">
                                <v-text-field v-model="request.output" type="number" min="0" append-outer-icon="add"
                                    prepend-icon="remove" @click:append-outer="increment('request', 'output')"
                                    @click:prepend="decrement('request', 'output')"
                                    :rules="[v => v >= 0 || 'Ausgang muss positiv sein']" />
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-textarea :clearable="true" filled label="Kommentar" v-model="request.comment"
                                    class="mx-3 mt-5" height="100px" no-resize />
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn block :disabled="loading || !validate" @click="submit" color="primary">
                                        {{ formCompleted }}
                                    </v-btn>
                                    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </v-container>
            </transition>
        </v-form><v-container v-if="showPrintertype">
            <v-row>
                <v-col cols="12">
                    <p><strong>Typ:</strong> {{  translation[item.type] }}</p>
                    <p><strong>Partner:</strong> {{ item.partner.display_name }}</p>
                    <p v-if="item.subcontractor.length > 0"><strong>Subunternehmer:</strong> {{ item.subcontractor }}</p>
                    <p><strong>Eingang:</strong> {{ item.input }}</p>
                    <p><strong>Ausgang:</strong> {{ item.output }}</p>
                    <p><strong>Kommentar:</strong> {{ item.comment }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" v-for="printer in printerOptions" :key="printer.value">
                    <v-btn block :disabled="loading || !validate" color="primary" @click="print(printer.value)">
                        {{ printer.label }}
                    </v-btn>
                </v-col>
                <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: "LoadingDeviceExchangeForm",
    data() {
        return {
            printerOptions: [
                { label: "Hinten Drucken", value: "backprint" },
                { label: "Vorne Drucken", value: "frontprint" },
                { label: "Büro Drucken", value: "officeprint" },
                { label: "Kein Druck", value: "noPrint" },
            ],
            showPrintertype: false,
            axios: null,

            loading: false,
            headlines: {
                removal: "Wie viele Paletten wurden entnommen?",
                insert: "Wie viele Paletten sind hinzugekommen?",
                exchange: "Wie viele Paletten wurden getauscht?",
                damage: "Wie viele Paletten sind Kaputt gegangen?"
            },
            translation: {
                removal: "Entfernung",
                insert: "Eingang",
                exchange: "Tausch",
                damage: "Schaden"
            },
            request: {
                type: null,
                partner_id: null,
                staff_id: null,
                input: 0,
                output: 0,
                comment: null,
                inventory: null,
                licensePlate: null,
                carrierType: null,
                printerType: null,
                subContractor: null, // Added new data property for Subunternehmer
            },
            item: {},
            // holen wir uns noch von der REST API 
            // api/v1/loading-device-exchange-types
            types: [],
            // api/v1/clients
            partners: [],
            // api/v1/loading-device-exchange-drivers
            // drivers: [],
            inventory: [],
        };
    },
    created() {
        this.fetchData()
    },
    computed: {
        formCompleted() {
            return this.validate ? "Weiter" : "Da fehlt noch was.";
        },
        validate() {
            let isValid = this.request.type !== null && this.request.output >= 0 && this.request.input >= 0 && this.request.partner_id !== null && (this.request.output > 0 || this.request.input > 0);
            if (this.isSpecialPartner) {
                isValid = isValid && !!this.request.licensePlate && this.request.carrierType !== null;
            }
            return isValid;
        },
        showInputFields() {
            return this.request.partner_id !== null;
        },
        isSpecialPartner() {
            if (this.request.partner_id === 200148) {
                return true;
            } else {
                return false;
            }
        },
        showSubContractorField() {
            return this.isSpecialPartner && this.request.carrierType == "2";
        }
    },
    watch: {
        "request.partner_id": function (newVal) {
            if (newVal !== 200148) {
                this.request.carrierType = 0;
                this.request.licensePlate = null;
                this.request.subContractor = null;
            } else {
                this.request.carrierType = null;
            }

        }
    },
    methods: {
        fetchData() {
            let _this = this;
            this.intranet.get('/loading-device-exchange-types').then((response) => _this.types = response.data.data);
            this.intranet.get('/loading-device-exchange-partners').then((response) => _this.partners = response.data);
        },
        increment(objectName, key) {
            this[objectName][key] = parseInt(this[objectName][key], 10) + 1;
        },
        decrement(objectName, key) {
            if (parseInt(this[objectName][key], 10) > 0) {
                this[objectName][key] = parseInt(this[objectName][key], 10) - 1;
            }
        },
        reset() {
            this.item = {
                type: null,
                partner_id: null,
                staff_id: null,
                input: 0,
                output: 0,
                comment: null,
                inventory: null,
                licensePlate: null,
                carrierType: null,
                printerType: null,
                subContractor: null,
            };
            this.request = {
                type: null,
                partner_id: null,
                staff_id: null,
                input: 0,
                output: 0,
                comment: null,
                inventory: null,
                licensePlate: null,
                carrierType: null,
                printerType: null,
                subContractor: null,
            };
            this.showPrintertype = false;
        },
        submit() {
            let _this = this;
            _this.loading = true;
            this.intranet.post('/loading-device-exchange', { ..._this.request })
                .then((response) => {
                    //this.request = response.data;
                    _this.item = response.data.data;
                    _this.playSound('success')
                   
                    _this.showPrintertype = true;
                })
                .catch(() => {
                    _this.playSound('error')
                })
                .finally(() =>
                    _this.loading = false,
                );
        },
        print(printerType) {
            let _this = this;
            _this.loading = true;
            _this.intranet.put("/loading-device-exchange/"+_this.item.id, {
                action: 'print',
                printerType: printerType
             }).then(() => {
                _this.playSound("success");
                _this.showPrintertype = false;
                }).catch(() => {
                    _this.playSound("error");
                }).finally(() => {
                    _this.loading = false;
                });
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
