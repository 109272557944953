<template>
    <v-app>
        <v-navigation-drawer v-if="isAuthenticated" v-model="drawer" app clipped>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ auth.profile.firstname }} {{ auth.profile.lastname }}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon @click="drawer = !drawer">
                            close
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/">
                    <v-list-item-icon>
                        <v-icon>dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/inbound">
                    <v-list-item-icon>
                        <v-icon>arrow_forward</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Eingang</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/outbound">
                    <v-list-item-icon>
                        <v-icon>arrow_back</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Ausgang</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/transfer">
                    <v-list-item-icon>
                        <v-icon>compare_arrows</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Umfuhr</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/inventory">
                    <v-list-item-icon>
                        <v-icon>archive</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Inventur</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/tools">
                    <v-list-item-icon>
                        <v-icon>apps</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Tools</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/asset">
                    <v-list-item-icon>
                        <v-icon>apps</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Assets</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout" class="pb-10">
                    <v-list-item-icon>
                        <v-icon>lock_open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app color="primary" clipped-left dark dense>
            <v-app-bar-nav-icon v-if="isAuthenticated" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <router-link to="/">
                <img src="./assets/logo.svg" alt="Hiller" style="padding-top:5px;" height="25">
            </router-link>
            <v-spacer></v-spacer>


            <v-toolbar-items>
                <template v-if="gate && gate.shortName && isAuthenticated">
                    <v-btn class="mx-n2" text @click="resetGate">
                        <v-icon>arrow_upward</v-icon>
                        {{ gate.shortName }}
                    </v-btn>
                </template>
                <v-btn class="mx-n2" v-if="shouldShowPalettExchange" @click="showExchange = !showExchange" icon>
                    <v-icon>sync_alt</v-icon>
                </v-btn>
                <v-btn class="mx-n2" v-if="isAuthenticated" @click="showChecklist = !showChecklist" icon>
                    <v-icon>developer_board</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-app-bar>
        <v-main>
            <v-dialog :value="messages.length" :retain-focus="false">
              <v-card>
                <v-card-text class="pt-10">
                  <v-alert v-for="(message,index) in messages" :key="index" :type="message.type" v-html="message.message"></v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" block @click="removeMessages()">
                      OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog :value="isOffline" persistent>
                <v-alert type="warning">
                    Du bist außerhalb des WLAN-Bereichs :-(<br>Bitte bewege dich :)
                </v-alert>
            </v-dialog>
            <div v-if="!showChecklist">
                <transition name="fade">
                    <router-view />
                </transition>
            </div>
        </v-main>
        <v-dialog v-if="isAuthenticated" v-model="showChecklist" fullscreen hide-overlay
            transition="dialog-right-transition">
            <v-card>
                <v-toolbar dark color="primary" dense>
                    <v-toolbar-title>Checkliste</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon @click="showChecklist = false">
                            <v-icon style="transform: rotate(90deg);">format_align_center</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <keep-alive :max="1">
                    <Checklist></Checklist>
                </keep-alive>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showExchange" transition="dialog-right-transition" :retain-focus="false">
            <v-card>
                <v-toolbar dark color="primary" dense>
                    <v-row>
                        <v-col cols="10">
                            <v-toolbar-title>Paletten Lieferschein</v-toolbar-title>
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="primary" ref="exchangeForm" @click="handleFormSuccess" fab x-small>
                                <v-icon>close</v-icon>
                            </v-btn></v-col>
                    </v-row>
                </v-toolbar>
                <loading-device-exchange-form  ref="exchangeForm" @formSubmitted="handleFormSuccess" />
            </v-card>
        </v-dialog>
    </v-app>
</template>
<style>
.v-speed-dial--bottom:not(.v-speed-dial--absolute),
.v-btn--bottom:not(.v-btn--absolute) {
    bottom: 50px;
}

#nprogress .bar {
    background: red;
    position: fixed;
    z-index: 1031;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
}

#nprogress .peg {
    display: none;
}

body,
html {
    overscroll-behavior-y: none;
}
</style>

<script>
import {mapGetters, mapState} from 'vuex';
import Checklist from "@/components/checklist/index";
import LoadingDeviceExchangeForm from "@/components/tools/exchange";
import {AUTH_LOGOUT} from "@/store/actions/auth";
import {mapFields} from "vuex-map-fields";

var EVENTS = ['online', 'offline', 'load'];

export default {
    components: { Checklist, LoadingDeviceExchangeForm },
    data: () => ({
        showChecklist: false,
        showExchange: false,
        drawer: false,
        info: null,
        onLine: navigator.onLine || false,
        global_error: false,
    }),
    computed: {
        ...mapFields(['gate']),
        ...mapState([
            'user',
            'auth',
            'messages'
        ]),
        ...mapGetters([
            'isAuthenticated'
        ]),
        shouldShowPalettExchange() {
            return this.isAuthenticated && parseInt(localStorage.getItem('warehouse')) == 1;
        },
        isOffline() {
            return !this.onLine;
        }
    },

    mounted() {
        var _this = this;
        EVENTS.forEach(function (event) {
            return window.addEventListener(event, _this.updateOnlineStatus);
        });
    },
    beforeDestroy: function beforeDestroy() {
        var _this2 = this;

        EVENTS.forEach(function (event) {
            return window.removeEventListener(event, _this2.updateOnlineStatus);
        });
    },
    methods: {
        resetGate() {
            let _this = this;
            _this.$store.dispatch("resetGate")
        },
        updateOnlineStatus: function updateOnlineStatus() {
            this.onLine = navigator.onLine || false;
        },
        reload: function () {
            location.reload();
        },
        removeMessages: function () {
            this.global_error = false;
            this.$store.commit('removeMessages');
        },
        handleFormSuccess() {
            this.$refs.exchangeForm.reset();
            this.showExchange = false;
        },
        logout: function () {
            this.intranet.post('auth/logout');
            this.axios.get('auth', { params: { type: 'logout' } }).then(() => {
                this.$store.dispatch(AUTH_LOGOUT).then(() => this.reload())
            });
        }
    },
}
</script>